.Plans{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;
}
.plan-categories{
    display: flex;
    gap: 2rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    color: white;
    justify-content: space-between;
}
.category>:nth-child(1){
    font-size: 2rem;
    font-weight: bold;
    padding-top: 1rem;
}
.category>:nth-child(2){
    font-size: 1rem;
    line-height: 25px;
    padding-top: 1rem;
}
.category:hover{
    background: rgb(163, 0, 0);
    cursor: pointer;
}
@media screen and (max-width: 768px){
    .plans-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 4.5rem ;
    }
    .plan-categories{
        flex-direction: column;
    }
}