.Footer-container{
    position: relative;

}
.Footer-container>hr{
    border: 1px solid gray;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.socials{
    display: flex;
    gap: 4rem;
}
.socials>a>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.logo-f{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50rem;
    font-size: 1rem;
    color: white;
}