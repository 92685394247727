.hero{
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main{
  padding-top: 4rem;
}
.slogan{
    margin-top: 1rem;
    background-color: rgb(163, 163, 163);
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
}
.slogan>span{
    z-index: 2;
}
.slogan>div{
    position: absolute;
    background-color:rgb(163, 0, 0);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 2;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
    
  }
.figures{
  margin-top: 2rem;
  display: flex;
  gap: 3rem;
}
.figures>div{
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.figures>div>span:nth-of-type(1){
  font-size: 2rem;
  color: white;
  text-transform: uppercase;
}
.figures>div>span:nth-of-type(2){
  text-transform: uppercase;
  color:rgb(163, 0, 0);
}
.btn-1{
  background-color: transparent;
  width: 15rem;
  height: 4rem;
  border: none;
  font-weight: bold;
}
.btn-1:hover{
  background-color: white;
  cursor: pointer;
}
.hero-buttons{
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-weight: bold;
  margin-bottom: 5rem;
}
.hero-buttons>:nth-child(1){
  color: white;
  background-color:rgb(163, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-buttons>:nth-child(2){
  color: rgb(187, 187, 187);
  background-color: transparent;
  border: 2px solid rgb(163, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-buttons>:nth-child(2):hover{
  background-color: white;
  cursor: pointer;
}
.right-h>.btn{
  position: absolute;
  right: 3rem;top: 2rem;color: black;
}
.hero-image{
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 28rem;
  z-index: 0;
}

.hero-blur{
  width: 22rem;
  height: 30rem;
  left: 1;
}

@media screen and (max-width: 768px){
  .hero{
    flex-direction: column;
  }
  .hero-blur{
    width: 14rem;
  }
  .slogan{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8)
  }
  .hero-text{
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(3){
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons{
    justify-content: center;
  }
  .figures{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
  }
  .figures > div > span > :nth-of-type(1){
    font-size: large; 
  }
  .figures > div > span > :nth-of-type(2){
    font-size: small;
  }
  .right-h{
    position: relative;
    background: none;
  }
  .hero-image{
    position: relative;
    margin-top: -9rem;
    width: 15rem;
    left: 7rem;
    align-self: center;
  }
}