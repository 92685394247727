.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: auto;
    height: 5rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}

.header-menu>li{
    cursor: pointer;
}
.header-menu>li:hover{
    cursor: pointer;
    color: rgb(163, 0, 0)
}
@media screen and (max-width: 768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        position: fixed;
        flex-direction: column;
        background-color: rgb(163, 0, 0);
        padding: 2rem;
    }
}